<script lang="ts">
  // import { Link } from "svelte-routing";
  const handleClick = () => {
    window.location.hash = "#contact";
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  };
</script>

<header>
  <nav>
    <ul>
      <!-- <Link to="/services"> -->
        <li class="nav-item">
          <svg
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            ><path
              d="M537.898667 175.829333l-271.530667 271.530667-21.973333 21.973333H853.333333a42.666667 42.666667 0 1 1 0 85.333334H253.013333l284.885334 284.928a42.666667 42.666667 0 0 1-60.330667 60.330666l-361.6-361.557333-0.426667-0.469333-0.512-0.512a42.666667 42.666667 0 0 1 0.469334-59.861334l362.026666-362.026666a42.666667 42.666667 0 1 1 60.373334 60.330666z"
              fill="#dedede"
            ></path></svg
          >
          首页
        </li>
      <!-- </Link> -->
    </ul>
    <ul class="nav-items">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li class="nav-item" on:click={handleClick}>联系我们</li>
    </ul>
  </nav>
</header>

<style>
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 100;
    font-size: 14px;
    padding: 0 8px;
    color: #dedede;
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 0 24px;
  }

  .nav-items {
    display: flex;
    align-items: center;
    /* height: 26px; */
    /* padding: 0 8px; */
    border-radius: 23px;
    cursor: pointer;
  }

  .nav-item {
    list-style: none;
    padding: 8px 24px;
    border: 1px solid #7d7d7d;
    border-radius: 23px;
    font-size: 12px;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .nav-item:hover {
    background-color: #7d7d7d;
    color: #bebebe;
  }

  @media screen and (max-width: 768px) {
    header,
    nav {
      display: none;
    }
  }
</style>
