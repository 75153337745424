<footer>
  <div class="footer">
    <div class="footer-content">
      <div class="footer-content-left">
        <div class="footer-content-left-text">
          <p>
            ©CopyRight 2023 Chengdu Ximi Cultural Technology Co., Ltd. All
            rights reserved.
          </p>
        </div>
      </div>
      <div class="footer-content-right">
        <div class="footer-content-right-links">
          <span></span>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >成都西咪文化科技有限公司</a
          >
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >蜀ICP备2023041979号-1</a
          >
        </div>
      </div>
    </div>
  </div>
</footer>

<style>
  .footer {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    font-size: 14px;
    color: #b8b6b6;
    text-align: center;
  }

  a {
    color: #b8b6b6;
    font-size: 14px;
  }

  .footer-content {
    padding: 48px 0;
  }
</style>
