<script>
  export let name = "Simi 开放商业合作平台";
  export let description = "链接全球文化资源，为文化产业提供一站式服务";
  export let text = "🚀";

  function showDialog() {
    const dialog = document.getElementById("dialog");
    dialog.showModal();
  }
</script>

<section>
  <h1>{name}</h1>
  <p>{description}</p>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="platform" on:click={showDialog}>{text}</div>
</section>

<style>
  section {
    height: 520px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 24px;
    font-weight: 200;
  }

  .platform {
    font-size: 14px;
    /* text-transform: uppercase; */
    /* color: #a7a7a7; */
    padding: 10px 24px;
    border: 1px solid #707070;
    border-radius: 20px;
    width: fit-content;
    cursor: pointer;
    margin-top: 38px;
  }

  .platform:hover {
    background-color: #b8b6b6;
    color: #252525;
  }

  h1 {
    font-size: 100px;
    font-weight: 200;
    color: #bebebe;
    /* letter-spacing: 5px; */
    /* color: #cccccc; */
    margin: 0;
    margin-bottom: 12px;
  }

  p {
    font-size: 18px;
    margin: 0;
    color: #bebebed7;
    line-height: 28px;
    max-width: 50%;
    font-weight: 100;
    /* text-align: center; */
  }

  @media screen and (max-width: 768px) {
    section {
      height: auto;
      padding: 48px 12px;
    }

    .platform {
      font-size: 10px;
      padding: 0px 8px;
      line-height: 22px;
    }

    h1 {
      font-size: 32px;
      font-weight: 300;
    }

    p {
      font-size: 16px;
      /* line-height: 28px; */
      max-width: 100%;
    }
  }
</style>
