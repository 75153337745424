<script>
  import Header from "./components/Header.svelte";
  import Banner from "./components/Banner.svelte";
  import Cooperation from "./components/Cooperation.svelte";
  import Contact from "./components/Contact.svelte";
  // import CooperationProcess from "./components/CooperationProcess.svelte";
  import Copyright from "./components/Copyright.svelte";
</script>

<main>
  <Header />
  <Banner 
    name="企业智能化解决方案"
    description="涵盖 AI 应用研发、大模型定制、数据清洗、微调、RAG 以及 Agent 等。构建贴合企业智能到大模型，推动企业精准决策，全面助力企业提升运营效率，在数字化浪潮中占得先机。"
    text="🚀    进入企业智能时代"
  />
  <Cooperation isProduct />
  <Contact />
  <!-- <CooperationProcess /> -->
  <Copyright />
</main>