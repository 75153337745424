<section id="contact">
  <h2>与我们取得联系</h2>
  <p>
    请通过以下方式与我们取得联系, 我们将在2个工作日内回复您的邮件或电话。
  </p>
  <ul class="contact">
    <li>
      <a href="mailto:bs@zstun.com">📧 邮件联系: bs@zstun.com</a>
    </li>
    <li>
      <a href="tel:+8617002830465">📱 Phone: +86 17002830465</a>
    </li>
  </ul>
</section>

<style>
  #contact {
    height: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 24px;
    font-weight: 100;
    text-align: center;
  }

  h2 {
    font-size: 100px;
    font-weight: 200;
    color: #bebebe;
    margin: 0;
    position: relative;
    margin-bottom: 12px;
  }

  p {
    font-size: 20px;
    margin: 0;
    margin-top: 12px;
    color: #b8b6b6;
    line-height: 28px;
  }

  ul.contact {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 36px;
  }

  ul.contact li {
    background-color: #282828;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    #contact {
      height: auto;
      margin: 88px 0;
    }

    h2 {
      font-size: 32px;
      font-weight: 300;
    }

    p {
      font-size: 16px;
    }

    ul.contact {
      flex-direction: column;
      gap: 8px;
    }

    ul.contact li {
      width: 100%;
    }
  }
</style>
