<script>
  export let isProduct = false;
  const serviceProducts = [
    {
      name: "创意内容服务",
      description:
        "深入品牌价值，帮您构建具有辨识度的品牌文化特质。利用大数据、人工智能技术搭建多元化传播网络，提升品牌信息传播效率与覆盖广度。",
      tags: [
        "文艺创作",
        "数字文化创意内容应用服务",
        "摄像及视频制作服务",
        "音频制作服务",
      ],
    },
    {
      name: "经纪代理&公关服务",
      description:
        "助推企业品牌化升级，精准链接文艺&媒体行业资源; 凭借专业项目策略、广告发xing、创意活动、公关服务, 帮助企业塑造品牌口碑。",
      tags: ["文化艺术经纪代理", "项目策划与公关服务", "广告制作与发行"],
    },
  ];

  const cooperations = [
    {
      name: "企业人工智能建设",
      description:
        "涵盖 AI 应用研发、大模型定制、数据清洗、微调、RAG 以及 Agent 等。构建贴合企业智能到大模型，推动企业精准决策，全面助力企业提升运营效率，在数字化浪潮中占得先机。",
      tags: [
        "行业大模型定制",
        "数据清洗",
        "模型微调",
        "RAG",
        "Agent",
        "AI应用研发",
      ],
    },
    {
      name: "技术研发 & 咨询",
      description: "提供企业上云、数字化转型、软件开发、DevOps等技术咨询服务。",
      tags: ["业务上云", "企业数字化", "DevOps"],
    },
  ];

  // dialog content state
  let dialogContent = {
    title: "",
    content: "",
  };

  function openDialog(post) {
    dialogContent = {
      title: post.name,
      content: post.description,
    };
    const dialog = document.getElementById("dialog");
    dialog.showModal();
  }

  function closeDialog() {
    const dialog = document.getElementById("dialog");
    dialog.close();

    // 清理表单数据
    const form = dialog.querySelector("form");
    form.reset();

    dialogContent = {
      title: "",
      content: "",
    };
  }

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    let data = Object.fromEntries(formData.entries());
    const isValid = Object.keys(data).every((key) => {
      if (!data[key]) {
        alert("请填写完整信息");
        return false;
      }
      return true;
    });

    data = {
      ...data,
      time: new Date().toLocaleString(),
      isMobile: window.innerWidth <= 768,
    };

    if (isValid) {
      const content = Object.entries(data)
        .map(([key, value]) => `- ${key?.toUpperCase()}: ${value}`)
        .join("\\ <br /> ");

      const title = `📮 收到一条新消息来自: ${data.name} 的商业合作申请`;
      fetch(
        `https://sctapi.ftqq.com/SCT5494TA-y0ChsDVaFZ868Dz7A3kMrjog.send?title=${title}&desp=${content}`
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.code === 0) {
            alert("提交成功，我们会尽快联系您！");
            closeDialog();
          } else {
            alert("提交失败，请稍后再试！");
          }
        });
      closeDialog();
    }
  }

  // 判断是否为移动端
  let isMobile = window.innerWidth <= 768;
  window.onresize = () => {
    isMobile = window.innerWidth <= 768;
  };
</script>

<section>
  <ul
    style={isMobile
      ? "grid-template-columns: repeat(1, 1fr);"
      : isProduct
        ? "grid-template-columns: repeat(2, 1fr);"
        : "grid-template-columns: repeat(4, 1fr);"}
  >
    {#each [...(isProduct ? [] : serviceProducts), ...cooperations] as post}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li on:click={() => openDialog(post)}>
        <div>
          <p class="title">
            <span># {post.name}</span>
          </p>
          <p class="desc">{post.description}</p>
          <div class="tags">
            {#each post.tags as item}
              <span>{item}</span>
            {/each}
          </div>
        </div>
        <div class="fake">
          <span>START NOW</span>
          <svg
            t="1732355708568"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3113"
            width="16"
            height="20"
            ><path
              d="M853.333 507.733H128V550.4h733.867L716.8 695.467l29.867 29.866 192-192-192-192L716.8 371.2l136.533 136.533z"
              fill="#989393"
              p-id="3114"
            ></path></svg
          >
        </div>
      </li>
    {/each}
  </ul>

  <dialog id="dialog">
    {#if dialogContent.title}
      <h1>
        {dialogContent.title}
      </h1>
    {/if}
    {#if dialogContent.content}
      <p>
        {dialogContent.content}
      </p>
    {/if}
    <button on:click={closeDialog}>关 闭</button>

    <h2>申请商业合作</h2>
    <form on:submit={handleSubmit}>
      <input type="text" name="name" placeholder="NICKNAME: 我们如何称呼您？" />
      <input
        type="email"
        name="email"
        placeholder="EMAIL: 我们建议您留个邮箱地址"
      />
      <input type="text" name="phone" placeholder="PHONE: 您的电话联系方式" />
      <input
        type="text"
        name="company"
        placeholder="COMPANY: 您的公司或您所在公司名称"
      />
      <textarea
        name="message"
        placeholder="SCENE: 您可以在这里描述您需要解决的业务问题或涉及的场景"
      ></textarea>
      <button type="submit"
        >提交需求 <svg
          t="1732355708568"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3113"
          width="20"
          height="20"
          ><path
            d="M853.333 507.733H128V550.4h733.867L716.8 695.467l29.867 29.866 192-192-192-192L716.8 371.2l136.533 136.533z"
            fill="#989393"
            p-id="3114"
          ></path></svg
        ></button
      >
    </form>
  </dialog>
</section>

<style>
  #dialog {
    background-color: #3c3c3c;
    padding: 36px 30px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
    width: 960px;
    min-height: 420px;
    border: none;
    position: fixed;
    box-shadow:
      rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  #dialog h1,
  #dialog h2 {
    font-size: 22px;
    font-weight: 100;
    color: #bebebe;
    margin: 0;
  }

  #dialog h2 {
    margin: 0;
    margin-top: 32px;
    font-weight: 100;
    margin-bottom: -10px;
  }

  #dialog p {
    font-size: 15px;
    font-weight: 100;
    color: #bebebedf;
    line-height: 28px;
    margin: 0;
    margin-top: 12px;
  }

  #dialog > button {
    position: absolute;
    top: 24px;
    right: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #404040;
    background-color: transparent;
    color: #bebebe;
    font-weight: 100;
    text-transform: uppercase;
  }

  #dialog button:hover {
    background-color: #404040;
  }

  #dialog form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
    margin-top: 32px;
  }

  #dialog input {
    padding: 16px 12px;
    border-radius: 3px;
    border: 1px solid #494949;
    background-color: transparent;
    /* color: white; */
    outline: none;
    background-color: #494949;
    color: #dbdbdb;
    font-size: 14px;
    font-weight: 200;
  }

  #dialog textarea {
    grid-column: 1 / -1;
    padding: 16px 12px;
    border-radius: 3px;
    border: 1px solid #494949;
    background-color: transparent;
    color: #dbdbdb;
    outline: none;
    background-color: #494949;
    resize: none;
    height: 120px;
    font-size: 14px;
    font-weight: 200;
  }

  #dialog input::placeholder,
  #dialog textarea::placeholder {
    font-size: 14px;
  }

  #dialog button[type="submit"] {
    grid-column: 1 / -1;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    border: none;
    background-color: #494949;
    border: 1px solid #494949;
    color: #bebebedf;
    cursor: pointer;
    transition: all 0.3s;
    width: 160px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 200;
  }

  /**
  */

  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* place-items: center; */
    font-weight: 100;
    gap: 1px;
  }

  li {
    height: 520px;
    padding: 36px 18px 18px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s;
    cursor: pointer;
    background-color: #282828;
  }

  li:hover {
    z-index: 1;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 0 0;
    background-color: #2f2f2f;
    color: white;
  }

  @media screen and (min-width: 768px) {
    li:hover {
      transform: translateY(-24px) scale(1);
    }
  }

  .title {
    font-size: 18px;
    font-weight: 200;
    margin: 0;
    margin-bottom: 24px;
    color: #bebebe;
  }

  .desc {
    font-size: 15px;
    font-weight: 100;
    color: #bebebe;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .tags {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  .tags > span {
    border: 1px solid #404040;
    font-size: 13px;
    padding: 3px 6px;
    border-radius: 4px;
    color: #bebebed7;
  }

  .fake {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
    color: rgb(228, 228, 228);
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  li:hover .fake {
    background-color: #404040;
  }

  @media (max-width: 768px) {
    ul {
      grid-template-columns: repeat(1, 1fr);
      margin: 0 4px;
      gap: 16px;
    }

    li {
      height: 360px;
      background-color: #282828;
      border-radius: 6px;
      transform: none;
    }

    .title {
      font-size: 20px;
      font-weight: 300;
      /* color: white; */
    }

    .desc {
      font-size: 15px;
      font-weight: 200;
      /* color: white; */
    }

    #dialog {
      width: 100%;
      padding: 24px 14px;
    }

    #dialog h1 {
      font-size: 20px;
      font-weight: 200;
      margin-bottom: 24px;
    }

    #dialog h2 {
      font-size: 18px;
      font-weight: 200;
      margin-top: 24px;
      margin-bottom: 12px;
    }

    #dialog p {
      font-size: 15px;
      line-height: 24px;
      font-weight: 200;
    }

    #dialog > form {
      grid-template-columns: repeat(1, 1fr);
    }

    #dialog > button {
      top: 12px;
      right: 12px;
      padding: 8px 8px;
      font-size: 12px;
      font-size: 14px;
    }

    #dialog input {
      padding: 12px 12px;
    }

    #dialog textarea {
      padding: 12px 12px;
    }

    /* #dialog button[type="submit"] {
      width: 100%;
    } */

    .tags > span {
      font-weight: 200;
    }
  }
</style>
