<script>
  import { Router, Route } from "svelte-routing";
  import App from "./App.svelte";
  import AI from "./AI.svelte";
  export let url = "";
</script>

<Router {url}>
  <div>
    <!-- svelte-ignore missing-declaration -->
    <Route path="/service" component={App} />
    <!-- svelte-ignore missing-declaration -->
    <Route path="/" component={AI} />
  </div>
</Router>
