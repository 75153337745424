<script>
  import Header from "./components/Header.svelte";
  import Banner from "./components/Banner.svelte";
  import Cooperation from "./components/Cooperation.svelte";
  import Contact from "./components/Contact.svelte";
  // import CooperationProcess from "./components/CooperationProcess.svelte";
  import Copyright from "./components/Copyright.svelte";
</script>

<main>
  <Header />
  <Banner text="🚀 开始商业合作" name="企业商业化合作平台" showTag description="链接全球文化资源，为文化产业提供一站式服务" />
  <Cooperation />
  <Contact />
  <!-- <CooperationProcess /> -->
  <Copyright />
</main>